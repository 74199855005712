import * as React from "react"
import headingImage from "../images/img14.svg"
import { graphql } from "gatsby"
import {
  PaddedContent,
  PageHeading,
  PageHeadingParagraph,
  PageWrapper,
} from "../components/modern/layout"
import { Card, CardSet } from "../components/modern/cards"

export const query = graphql`
  query {
    allMarkdownRemark(filter: { frontmatter: { key: { eq: "group" } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            description
            slug
            logo {
              publicURL
            }
          }
        }
      }
    }
  }
`

// TODO These data structures need to be sorted out, in particular the dual
// "slug". One of them (in `frontmatter`) yields dead links.
type Group = {
  fields: {
    slug: string
  }
  frontmatter: {
    title: string
    description: string
    slug: string
    logo?: { publicURL: string }
  }
}

type Edge = {
  node: Group
}

type Props = {
  data: {
    allMarkdownRemark: {
      edges: Edge[]
    }
  }
}

const Groups: React.FC<Props> = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const cards: Card[] = edges.map(edge => {
    const group = edge.node.frontmatter
    return {
      slug: group.slug,
      content: {
        title: group.title,
        icon: group.logo ? { src: group.logo.publicURL } : undefined,
        description: group.description,
        button: {
          label: `Read more`,
          src: edge.node.fields.slug,
        },
      },
    }
  })

  return (
    <PageWrapper title="Groups">
      <PageHeading title="Technical Groups" imageSrc={headingImage}>
        <PageHeadingParagraph>
          The Tweag team’s collective goal is to have a positive impact on the
          software industry’s practices. To do this, we apply, develop and
          evolve tools and techniques to better address tricky problems, and
          share those solutions with our clients and the software community.
        </PageHeadingParagraph>
        <PageHeadingParagraph>
          Technical groups within Tweag focus on specific industry problem
          spaces, and our current groups are listed here:
        </PageHeadingParagraph>
      </PageHeading>
      <PaddedContent>
        <CardSet cards={cards} />
      </PaddedContent>
    </PageWrapper>
  )
}

export default Groups
