import * as React from "react"
import { Flex, Image, Paragraph } from "theme-ui"
import { SimpleButton } from "./buttons"

export type Card = {
  slug: string
  content: {
    icon?: {
      src: string
    }
    title: string
    subtitle?: string
    tags?: string[]
    description: string
    button?: {
      label: string
      src: string
    }
  }
  theme?: {
    size?: `simple` | `double`
    color?: string
    extraPadding?: boolean
    center?: boolean
    backgroundIcon?: boolean
  }
}

const cardWidthEm = 20
const cardSetGapEm = 2

const Card: React.FC<{ card: Card }> = ({ card }) => {
  const backgroundColor = !card?.theme?.color ? `greyLight` : card.theme.color
  const textColor = !card?.theme?.color ? `black` : `white`
  const buttonTheme = {
    textColor: card?.theme?.color ? card.theme.color : `white`,
    backgroundColor: card?.theme?.color ? `white` : `blue`,
  }
  const padding =
    card.theme && card.theme.extraPadding ? [`2em`, `5em`] : [`2em`, `2em`]
  return (
    <Flex
      sx={{
        width: `${
          card.theme && card.theme.size === `double`
            ? 2 * cardWidthEm + cardSetGapEm
            : cardWidthEm
        }em`,
        padding: padding,
        background: backgroundColor,
        borderRadius: `1em`,
        flexDirection: `column`,
        justifyContent:
          card.theme && card.theme.center ? `center` : `flex-start`,
        overflow: `hidden`,
        position: `relative`,
      }}
    >
      {card.content.icon ? (
        <Image
          src={card.content.icon.src}
          sx={
            card?.theme?.backgroundIcon
              ? {
                  width: `15em`,
                  height: `15em`,
                  objectFit: `contain`,
                  position: `absolute`,
                  filter: `opacity(20%) saturate(0%)`,
                  mixBlendMode: `multiply`,
                  top: `-2em`,
                  left: `-2em`,
                }
              : {
                  width: `5em`,
                  height: `5em`,
                  objectFit: `contain`,
                  marginBottom: `1.3em`,
                }
          }
        />
      ) : null}
      <Paragraph
        sx={{
          fontSize: `1.2em`,
          fontWeight: `bold`,
          color: textColor,
          zIndex: `1`,
        }}
      >
        {card.content.title}
      </Paragraph>
      {card.content.subtitle ? (
        <Paragraph
          sx={{
            fontSize: `1em`,
            color: textColor,
            zIndex: 1,
          }}
        >
          {card.content.subtitle}
        </Paragraph>
      ) : null}
      {card.content.tags ? (
        <Paragraph
          sx={{
            color: textColor,
            opacity: `50%`,
            zIndex: 1,
          }}
        >
          {card.content.tags.join(`, `)}
        </Paragraph>
      ) : null}
      <Paragraph
        sx={{
          marginTop: `1em`,
          marginBottom: `2em`,
          color: textColor,
          flexGrow: card?.theme?.center ? 0 : 1,
          zIndex: 1,
        }}
      >
        {card.content.description}
      </Paragraph>
      {card.content.button ? (
        <SimpleButton
          label={card.content.button.label}
          url={card.content.button.src}
          theme={buttonTheme}
        />
      ) : null}
    </Flex>
  )
}

export const CardSet: React.FC<{ cards: Card[] }> = ({ cards }) => (
  <Flex
    sx={{
      flexWrap: `wrap`,
      alignItems: `stretch`,
      justifyContent: `center`,
      gap: `${cardSetGapEm}em`,
    }}
  >
    {cards.map(card => (
      <Card card={card} key={card.slug} />
    ))}
  </Flex>
)
